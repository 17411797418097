.password-main{background:url('https://cdn.shopify.com/s/files/1/0565/2663/5169/files/8300-SG-9--674.jpg?v=1639698819') background-size:cover;}

.d-none{display:none;}

body[type="product"] .product{opacity:0;}
.product .cat{margin-bottom:10px!important;}
.product .cat span, .product .cat a{ color:#999;}
.product .entry-summary .price_varies{color:#333;  font-weight:600; font-size:24px;     margin-top: 40px;    margin-bottom: 40px;}
.product .price_stock{border:none;}
.product .product_title{font-weight:100; font-size:42px;}
.product .thumbnails-wrapper ul{list-style: none;}
.product .thumbnails-wrapper ul li{    width: 80px;}
.product-img-container button{display:none;}
.product .swatch{margin-bottom:0;}
.product .swatch__title{display:inline-block; float:left;     margin-top: 3px;    margin-bottom: 0;     font-weight: 400;    text-transform: capitalize;}
.product .nt_lt_fake{display:inline-block; margin: 0;}
.product .header_picker{border:none;     padding: 3px 30px 0 10px; height: 22px;  color: #B58A48;  line-height: 20px;}
.product .style__simple .nt_lt_fake>i{top:5px; font-size:16px; color: #B58A48;}
.product .spec-value{color:#999; padding-left:10px;}

@media (max-width: 768px) {
  .product .thumbnails-wrapper ul li{display: inline-block;}
  .product .variations {
    position: absolute;
    top: -700px;
    z-index: 99;
	}
  .product-images{padding-top: 230px;    height: 800px;}
  .product .variations {    position: absolute;    top: -800px;}
  .product-img-container{z-index:0;}
}